export const siteConfig = {
  "name": "sprejecz",
  "slogan": "slogan",
  "domain": "https://sprejecz.online",
  "icon": "/favicon.ico",
  "appleIcon": "/apple-touch-icon.png",
  "theme": {
    "headFont": "font-leckerli",
    "primaryColor": "blue-500",
    "fontColor": "text-gray-900",
    "backgroundColor": "bg-white"
  },
  "gaId": "G-TH4CPLYEKC",
  "plausible": "",
  "navbarItems": [
    {
      "title": "home",
      "href": "/"
    },
    {
      "title": "guide",
      "href": "/guide"
    },
    {
      "title": "information",
      "href": "/t"
    }
  ],
  "gameType": "iframe",
  "gameIframeUrl": "https://s.sprunkiincredibox.com/game/sprunki/index.html",
  "gameDownloadBg": "",
  "gameDownloadUrl": "",
  "isShowFAQs": true,
  "isShowVideo": true,
  "isShowGuide": true,
  "videos": [
    {
      "title": "",
      "url": ""
    }
  ],
  "isShowComments": true,
  "isShowTweets": false,
  "useRealTweets": null,
  "tweets": [
    ""
  ],
  "comments": [
    {
      "author": "Lorenz S.",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Lorenz S..png",
      "content": "This game is so much fun! I love the colorful graphics and the catchy music. The gameplay is simple but addictive, and there are plenty of levels to keep me entertained. I highly recommend this game to anyone who loves music or just wants to have a good time."
    },
    {
      "author": "MK",
      "role": "player",
      "avatar": "https://api.multiavatar.com/MK.png",
      "content": "I'm Sprunki to the core! This game is a blast! The graphics are colorful and the music is infectious. The levels are challenging and the gameplay is smooth. I can't get enough of it!"
    },
    {
      "author": "Ben Stephens \u2014 \ud83d\udc3f\ufe0f/acc",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Ben Stephens \u2014 \ud83d\udc3f\ufe0f/acc.png",
      "content": "I love this game! It's so easy to play and yet so addictive. The graphics are bright and cheerful, and the music is great. I highly recommend it!"
    },
    {
      "author": "Packy McCormick",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Packy McCormick.png",
      "content": "This game is amazing! The graphics are beautiful and the music is so catchy. The gameplay is simple but challenging, and there are so many levels to keep me entertained. I highly recommend this game to anyone who loves music or just wants to have a good time."
    },
    {
      "author": "Lex Fridman",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Lex Fridman.png",
      "content": "I'm Sprunki happy! This game is a total blast! The graphics are colorful and the music is infectious. The gameplay is simple but challenging, and there are plenty of levels to keep me entertained. I highly recommend this game to anyone who loves music or just wants to have a good time."
    },
    {
      "author": "Mike Taylor",
      "role": "player",
      "avatar": "https://api.multiavatar.com/Mike Taylor.png",
      "content": "This game is so much fun! I love the colorful graphics and the catchy music. The gameplay is simple but addictive, and there are plenty of levels to keep me entertained. I highly recommend this game to anyone who loves music or just wants to have a good time."
    }
  ],
  "isShowRecommendation": true,
  "friendLinks": [
    {
      "title": "",
      "url": ""
    }
  ],
  "recommendations": {
    "en": [
      {
        "title": "Sprunki Phase 4",
        "cover": "https://img.itch.zone/aW1nLzE4NDc2Nzk0LmpwZWc=/315x250%23c/rnrsj7.jpeg",
        "url": "https://loganadofficial.itch.io/incredibox-sprunki-phase-4"
      }
    ],
    "": [
      {
        "title": "Sprunki Phase 4",
        "cover": "https://img.itch.zone/aW1nLzE4NDc2Nzk0LmpwZWc=/315x250%23c/rnrsj7.jpeg",
        "url": "https://loganadofficial.itch.io/incredibox-sprunki-phase-4"
      },
      {
        "title": "",
        "cover": "",
        "url": ""
      }
    ]
  },
  "faviconBgColor": null,
  "faviconTextColor": null,
  "faviconText": "SJ"
};
